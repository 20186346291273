.formpage{
    input{
        border: 1px solid rgba(136, 136, 136, 0.30);
    }
    ul{
        li{
            .wrapper{
                border: 1px solid rgba(136, 136, 136, 0.30);   
            }
        }
    }
}
.header{
    .pages{
        @media only screen and (max-width:768px){
            display: none;
        }
        a{
            position: relative;
            &:hover{
                &::after{
                    width: 100%;
                }
            }
            &::after{
                position: absolute;
                content: "";
                bottom: -2px;
                left: 0;
                width: 0%;
                height: 2px;
                border-radius: 100px;
                background: #27BDBE;
                transition: 0.3s;
            }
        }
    }
    .assets{
        @media only screen and (max-width:768px){
            display: none;
        }
    }
}
.mobileNavBackdrop{
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 20;
}
.slick-dots li.slick-active button:before{
    color: transparent;
    border: 3px solid #27BDBE;
    border-radius: 50%;
    transform: scale(1.4);
}
.slick-dots li button:before{
    width: 10px;
    height: 10px;
    color: transparent;
    border: 3px solid black;
    border-radius: 50%;
    opacity: 100%;
}
.slick-dots li{
    width: 10px;
    height: 10px;
}
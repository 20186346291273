.login{
    position: relative;
    &__gradient{
        position: absolute;
        width: 766px;
        height: 766px;
        border-radius: 766px;
        opacity: 0.94;
        background: #27BDBE;
        filter: blur(420.25px);
        left: -100px;
        bottom: -200px;
        z-index: -1;
    }
    form{
        input{
            border: 1px solid rgba(136, 136, 136, 0.30);
        }
        .radio{
            label{
                font-size: 10px;
            }
        }
    }
    .social{
        button{
            box-shadow: 0px 0.778px 1.167px 0px rgba(0, 0, 0, 0.17), 0px 0px 1.167px 0px rgba(0, 0, 0, 0.08);
        }
    }
    .button{
        a{
            button{
                width: 100%;
            }
        }
    }
}


.signin{
    position: relative;
    &__gradient{
        position: absolute;
        width: 766px;
        height: 766px;
        border-radius: 766px;
        opacity: 0.94;
        background: #27BDBE;
        filter: blur(420.25px);
        left: -100px;
        bottom: -200px;
        z-index: -1;
    }
    &__wrapper{
        box-shadow: 5px 4px 29.5px 0px rgba(0, 0, 0, 0.10);
    }
}
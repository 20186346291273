.search{
    position: relative;
    &__gradient{
        position: absolute;
        width: 766px;
        height: 766px;
        flex-shrink: 0;
        border-radius: 766px;
        opacity: 0.94;
        background: #27BDBE;
        filter: blur(420.25px);
        z-index: -1;
        left: -200px;
        bottom: -250px;
    }
    &__input{
        input{
            border: 1px solid rgba(136, 136, 136, 0.50);
        }
    }
}
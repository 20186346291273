.image-gallery-content{
    display: flex;
    direction: rtl;
    align-items: center;
}
.image-gallery-thumbnails-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}
.image-gallery-slide-wrapper.image-gallery-thumbnails-bottom{
    width: 100%;
}
.image-gallery-right-nav{
    display: none;
}
.image-gallery-left-nav{
    display: none;
}
.image-gallery-play-button{
    display: none;
}
@media only screen and (max-width:768px){
    .image-gallery-content{
        flex-direction: column;
    }
    .image-gallery-thumbnails-container{
        flex-direction: row;
    }
}
.successpay{
    min-height: 90vh;
    .active{
        position: relative;
        &::after{
            position: absolute;
            content: "";
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 3px;
            background: black;
            border-radius: 10px;
        }
    }
}
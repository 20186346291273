.loadingsvg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;  // Adjust the height as needed
  }
  
  .spinner {
    animation: spin 1s linear infinite;
    width: 40px;  // Adjust the size as needed
    height: 40px;  // Adjust the size as needed
  }
  
  .path {
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }
  
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
.OutlineBlueButton{
    border: 3px solid #27BDBE;
}

.OutlineBlackButton{
    border: 3px solid black;
}

.BluePrimaryButton{
    border: 3px solid #27BDBE;
}
.DeBluePrimaryButton{
    border: 3px solid #7FD4D5;
    background: rgb(127, 212, 213,50%);
}
.formpage{
    input{
        border: 1px solid rgba(136, 136, 136, 0.30);
    }
    .totalprice{
        background: rgba(217, 217, 217, 0.5);
    }
    .active{
        position: relative;
        &::after{
            position: absolute;
            content: "";
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 3px;
            background: black;
            border-radius: 10px;
        }
    }
}

@font-face {
  font-family: "SF UI Text Regular";
  src: url("./assets/fonts/5ad142d0e49ca868b1530c30a47c625f.woff2") format("woff2");
}

body{
  overflow: hidden;
  background: white;
}
html{
  overflow-x: hidden;
}
.noScroll{
  overflow: hidden;
  height: 100vh;
}



*{
  -webkit-tap-highlight-color: transparent;
    /* -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
}

*{
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*::before , *::after{
  box-sizing: border-box;
}
body{
  font-family: sans-serif;
  line-height: 1.5;
  -webkit-font-somoothing: antialiased;
}
button{
  cursor: pointer;
}
button,input,textarea,select{
  font: inherit;
}
p,h1,h2,h3,h4,h5,h6,input,button,a{
  overflow-wrap: break-word;
  font-family: "SF UI Text Regular";
}
img,video,canvas,svg{
  max-width: 100%;
}
a{
  text-decoration: none;
  color: inherit;
}
button{
  border: none;
  outline: none;
}

.redinput{
  border: 1px solid red !important;
}